<template lang="pug">
  v-container#advance(fluid='' tag='section' style="padding: 0px;")
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-dialog(fullscreen="" v-model='dialogAdvance')
      v-card
        advance-modal(@closeModal="closeAdvanceModal" :advanceToEdit="advanceToEdit")
    v-dialog(v-model='dialogDeleteAdvance' width='400')
      template(v-slot:activator='{ on, attrs }')
      v-card
        v-card-title.text-h5.grey.lighten-2
          | Eliminar Anticipo
        v-card-text
          | ¿Estás seguro de eliminar este ANTICIPO.?
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='red' text='' @click='dialogDeleteAdvance = false')
            | Salir
          v-btn(color='primary' text='' @click='deleteAdvanceConfirm' :loading="loadingDeleteAdvance")
            | Aceptar
    base-material-card.px-5.py-3(icon='mdi-cash-multiple' title="Ingreso de Anticipos" style='min-height: 100vh;')
      v-container#inventory-table(fluid='' tag='section')
        v-row(style="margin-bottom: 0px;")
          v-col(cols="12" md="5" style="margin-top: 10px;")
            v-menu(ref='menuFrom' v-model='menuFrom' :close-on-content-click='false' :return-value.sync='dateFrom' transition='scale-transition' offset-y='' min-width='290px')
              template(v-slot:activator='{ on }')
                v-text-field(readonly v-model='dateFrom' label='Buscar Desde' prepend-icon='mdi-calendar' v-on='on')
              v-date-picker(@change='$refs.menuFrom.save(dateFrom)' v-model='dateFrom' no-title='' scrollable='' :max="dateUntil" style='margin:0px;' color="green lighten-1" :min="minDateForConsults")
          v-col(cols="10" md="5" style="margin-top: 10px;")
            v-menu(ref='menuUntil' v-model='menuUntil' :close-on-content-click='false' :return-value.sync='dateUntil' transition='scale-transition' offset-y='' min-width='290px')
              template(v-slot:activator='{ on }')
                v-text-field(readonly v-model='dateUntil' label='Buscar Hasta' prepend-icon='mdi-calendar' v-on='on')
              v-date-picker(@change='$refs.menuUntil.save(dateUntil)' v-model='dateUntil' no-title='' scrollable='' :min="dateFrom" style='margin:0px;' color="green lighten-1")
          v-col(cols="2" md="2" style="margin-top: 15px;")
            v-tooltip(bottom='')
              template(v-slot:activator='{ attrs, on }')
                v-btn(v-on="on" icon='' color='gray' style="background-color: RGB(1, 125, 71);" @click="filterByDate" :loading="loadingDataFilterDate")
                  v-icon(style="font-size: 30px; color: white;") mdi-magnify
              span.tooltips FILTRAR POR FECHA
          v-col(cols="12" md="8")
            v-text-field(v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
          v-col(cols="12" md="2")
            v-btn.mr-0(color='primary' rounded='' @click='addNewAdvace' style="width: 100%;")
              strong
                | NUEVO
                br
                | ANTICIPO
          v-col(cols="12" md="2")
            v-btn.mr-0(color='green' rounded='' @click='downloadAdvance' style="width: 100%;")
              strong
                | DESCARGAR
                br
                | ANTICIPOS
        v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
        v-data-table.mx-2#tableGuides(:search="search" :headers="headers" v-show="!firstLoad" :items="advanceLoadLst" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" :sort-desc="true" calculate-widths :footer-props="{\'items-per-page-text':'Guías por página'\}")
          template(v-slot:[`item.status`]='{ item }')
            v-chip(:color="getColor(item.status)" small label) {{ item.status ? item.status : 'PAGADO' }}
          template(v-slot:item.action="{ item }")
            v-row
              v-col(cols="12" md="12")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(text="" icon="" color='blue' v-on="on" @click="editAdvance(item)")
                      v-icon(small) mdi-eye
                  span.tooltips Visualizar Anticipo
              //v-col(cols="6" md="6")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(:disabled="item.status !== 'PENDIENTE'" text="" icon="" color='red' v-on="on" @click="deleteAdvance(item)")
                      v-icon(small) mdi-trash-can-outline
                  span.tooltips Eliminar Anticipo
          v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
          | Sin resultados
          v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
            | Sin datos
          v-flex(xs12='', sm10='', offset-sm1='')
</template>
<script>
  import moment from 'moment'
  // import readXlsxFile from 'read-excel-file'
  export default {
    components: {
      AdvanceModal: () => import('@/views/dashboard/component/application/AdvanceModal'),
    },
    data: data => ({
      minDateForConsults: moment().subtract(15, 'days').format('YYYY-MM-DD'),
      loadingDeleteAdvance: false,
      dialogDeleteAdvance: false,
      loadingDataFilterDate: false,
      dialogAdvance: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      menuUntil: false,
      menuFrom: false,
      dateUntil: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10),
      dateFrom: moment(new Date()).format('YYYY-MM-DD'),
      search: '',
      headers: [
        { text: 'Fecha', value: 'createDate', align: 'left', filterable: true },
        { text: 'Proveedor', value: 'provider.name', align: 'left', filterable: true },
        { text: 'Total', value: 'total', align: 'left', filterable: true },
        { text: 'Pagado', value: 'paid', align: 'left', filterable: true },
        { text: 'Saldo', value: 'balance', align: 'left', filterable: true },
        { text: 'Estado', value: 'status', align: 'left', filterable: true },
        { text: 'ACCIONES', align: 'center', value: 'action', sortable: false },
      ],
      cargando: false,
      firstLoad: false,
      ordenarPor: '',
      advanceToEdit: {
        createDate: moment(new Date()).format('YYYY-MM-DD'),
        provider: null,
        total: '',
        concept: '',
        paid: '',
        balance: '',
        status: 'PENDIENTE',
      },
    }),
    computed: {
      plan () {
        return this.$store.state.suscriptionPlan.plan
      },
      advanceLoadLst () {
        return this.$store.state.advance.advancesLst
      },
    },
    watch: {
      plan () {
        if (this.plan.diasConsulta) {
          this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
        }
      },
    },
    methods: {
      deleteAdvance (item) {
        this.dialogDeleteAdvance = true
        this.advanceToEdit = item
      },
      deleteAdvanceConfirm () {
        this.loadingDeleteAdvance = true
        this.$store.dispatch('advance/deleteAdvance', this.advanceToEdit.id).then(() => {
          this.loadingDeleteAdvance = false
          this.dialogDeleteAdvance = false
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Anticipo eliminado correctamente',
          }
        }).catch(() => {
          this.loadingDeleteAdvance = false
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al eliminar el anticipo',
          }
        })
      },
      getColor (valor) {
        if (valor === true || valor !== 'PENDIENTE') {
          return 'green'
        } else {
          return 'red'
        }
      },
      addNewAdvace () {
        this.dialogAdvance = true
        this.advanceToEdit = {
          createDate: moment(new Date()).format('YYYY-MM-DD'),
          provider: null,
          total: '',
          concept: '',
          paid: '',
          balance: '',
          status: 'PENDIENTE',
        }
      },
      downloadAdvance () {},
      closeAdvanceModal () {
        this.dialogAdvance = false
      },
      editAdvance (item) {
        this.dialogAdvance = true
        this.advanceToEdit = item
      },
      filterByDate () {},
    },
    mounted () {
      if (this.plan.diasConsulta) {
        this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
      }
    },
  }
</script>
